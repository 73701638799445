<messages>["../UserCreateUpdate"]</messages>

<template>
  <v-autocomplete
    v-model="selectedGroups"
    multiple
    class="required"
    :label="$t('groups')"
    :items="availableGroups"
    item-text="name"
    item-value="id"
    :error-messages="errorMessages"
    @blur="blur">
    <template #selection="{ item, parent }">
      <v-chip
        :key="item.id"
        close
        outlined
        color="black"
        class="my-1"
        @update:active="parent.selectItem (item)">
        <span
          class="chipContent"
          :title="item.name">
          {{ item.name }}
        </span>
      </v-chip>
    </template>
    <template #item="{ item }">
      <v-list-item-action>
        <v-checkbox
          :input-value="value.includes(item.id)"
          readonly
          :color="selectedGroups.includes(item.id) ? 'primary' : 'default'"/>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title v-html="item.name"/>
      </v-list-item-content>

      <v-list-item-action
        @click.prevent="(e)=>e.stopPropagation()"
        @input.prevent="(e)=>e.stopPropagation()">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon v-on="on" @click="setDialog([item])">
              info
            </v-icon>
          </template>
          {{ $t ('click') }}
        </v-tooltip>
      </v-list-item-action>
    </template>
    <template #append-outer>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon v-on="on" @click="setDialog(selectedGroupsDetailes)">
            info
          </v-icon>
        </template>
        {{ $t ('click') }}
      </v-tooltip>
      <base-dialog
        v-model="dialog"
        scrollable
        mw0>
        <user-group-info-card :groups="groups" @close="dialog = false"/>
      </base-dialog>
    </template>
  </v-autocomplete>
</template>

<script>
  import UserGroupInfoCard from './UserGroupInfoCard'
  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    name: 'UserGroupInput',

    components: {
      UserGroupInfoCard,
      BaseDialog
    },

    props: {
      value: {
        type: Array,
        required: true
      },
      availableGroups: {
        type: Array,
        required: true
      },
      errorMessages: {
        type: Array,
        required: false,
        default: () => []
      }
    },

    data () {
      return {
        dialog: false,
        groups: null
      }
    },

    computed: {
      selectedGroups: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      selectedGroupsDetailes () {
        return this.availableGroups.filter ((g) => {
          return this.value.find ((v) => v === g.id)
        })
      }
    },

    watch: {
      value () {
        this.$forceUpdate ()
      }
    },

    methods: {
      blur () {
        this.$emit ('blur')
      },

      setDialog (groups) {
        this.groups = groups
        this.dialog = true
      }
    }
  }
</script>
