var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"required",attrs:{"multiple":"","label":_vm.$t('groups'),"items":_vm.availableGroups,"item-text":"name","item-value":"id","error-messages":_vm.errorMessages},on:{"blur":_vm.blur},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var parent = ref.parent;
return [_c('v-chip',{key:item.id,staticClass:"my-1",attrs:{"close":"","outlined":"","color":"black"},on:{"update:active":function($event){parent.selectItem (item)}}},[_c('span',{staticClass:"chipContent",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.value.includes(item.id),"readonly":"","color":_vm.selectedGroups.includes(item.id) ? 'primary' : 'default'}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}})],1),_c('v-list-item-action',{on:{"click":function($event){$event.preventDefault();return (function (e){ return e.stopPropagation(); })($event)},"input":function($event){$event.preventDefault();return (function (e){ return e.stopPropagation(); })($event)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.setDialog([item])}}},on),[_vm._v(" info ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t ('click'))+" ")])],1)]}},{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.setDialog(_vm.selectedGroupsDetailes)}}},on),[_vm._v(" info ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t ('click'))+" ")]),_c('base-dialog',{attrs:{"scrollable":"","mw0":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('user-group-info-card',{attrs:{"groups":_vm.groups},on:{"close":function($event){_vm.dialog = false}}})],1)]},proxy:true}]),model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}})}
var staticRenderFns = []

export { render, staticRenderFns }