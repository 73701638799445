<template>
  <v-row
    align="center"
    class="nameValue">
    <v-col
      cols="12"
      class="pa-1 valueContainer">
      <span
        v-if="value && !link"
        class="value"
        :title="value"
        v-text="value"/>
      <router-link
        v-if="link"
        class="value"
        :to="link">
        {{ value }}
      </router-link>
      <div v-else class="value">
        <slot name="default"/>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    props: {
      value: {
        type: [String, Boolean],
        required: true
      },
      valueSlot: {
        type: Object,
        default: undefined
      },
      link: {
        type: Object,
        default: undefined
      }
    },

    watch: {
      valueSlot (newVal) {
        if (newVal) {
          this.$slots.default = newVal
        }
      }
    },

    created () {
      if (this.valueSlot) {
        this.$slots.default = this.valueSlot
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/settings/_colors';

  $oddColor: map-get($grey, "lighten-2");
  $evenColor: map-get($grey, "lighten-5");

  .nameValue {
    background-color: $oddColor;
  }

  .nameValue:nth-child(even) {
    background-color: $evenColor;
  }

  .valueContainer {
    min-width: 0;

    > .value {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
