<messages>["../UserCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <!-- "user does not exist" alert -->
    <v-alert
      v-if="!isLoading && !isCreate && !user.name"
      type="error">
      {{ $t ('invalidUserId', { id: user.id }) }}
    </v-alert>

    <v-card v-else>
      <form
        novalidate
        @submit.prevent="processUserData">
        <v-card-title primary-title>
          <div>
            <div
              v-t="`user.cu.title.${operationName}`"
              class="text-h5"/>
            <div
              v-t="`subtitle.${operationName}`"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-if="isCreate"
                v-model="user.clientId"
                class="required"
                :label="$t('clientSelect')"
                :items="clientOptions"
                :disabled="!needSpecifyClient"
                :required="needSpecifyClient"
                hide-details/>

              <v-text-field
                v-else-if="parentName"
                v-model="parentName"
                :label="$t('client')"
                disabled
                hide-details/>
            </v-col>

            <v-col
              v-if="mayCreateAdminUsers"
              cols="6">
              <v-switch
                v-model="user.admin"
                :label="$t('admin')"/>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="user.active"
                :label="$t('active')"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="user.name"
                name="username"
                spellcheck="false"
                class="required"
                :disabled="!isCreate"
                :label="$t('name')"
                :error-messages="validationErrors(
                  'user.name',
                  {
                    required: 'required.name',
                    userName: 'invalid.userName'
                  })"
                @blur="$v.user.name.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="user.displayName"
                class="required"
                :label="$t('displayName')"
                :error-messages="validationErrors(
                  'user.displayName',
                  {
                    required: 'required.displayName'
                  })"
                @blur="$v.user.displayName.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="user.emailAddress"
                spellcheck="false"
                class="required"
                :label="$t('email')"
                :error-messages="validationErrors(
                  'user.emailAddress',
                  {
                    required: 'required.email',
                    email: 'general.invalid.email'
                  })"
                @blur="$v.user.emailAddress.$touch"/>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="user.preferredLanguage"
                class="required"
                :label="$t('preferredLanguage')"
                :items="preferredLanguageOptions"
                :error-messages="validationErrors(
                  'user.preferredLanguage',
                  {
                    required: 'required.preferredLanguage'
                  })"
                @blur="$v.user.preferredLanguage.$touch"/>
            </v-col>

            <v-col cols="12">
              <user-group-input
                v-model="user.groups"
                :available-groups="userGroups"
                :error-messages="validationErrors(
                  'user.groups',
                  {
                    required: 'required.groups'
                  })"
                @blur="$v.user.groups.$touch"/>
            </v-col>

            <v-col
              v-if="!isCreate"
              cols="12">
              <v-switch
                v-model="changePassword"
                hide-details
                :label="$t('changePassword')"/>
            </v-col>

            <v-col
              v-if="isPasswordRequired || resetPassword"
              cols="12" sm="6">
              <!--
                these fields are a workaround to prevent autocompletion
                of the field (GWNG-863/GWNG-416)
              -->
              <input id="email" style="display:none" type="text" name="ffWorkaroundEmail">
              <input id="password" style="display:none" type="password" name="ffWorkaroundPassoword">
              <v-text-field
                v-model="user.password"
                autocomplete="new-password"
                name="password"
                spellcheck="false"
                :class="{ required: !resetPassword }"
                :disabled="resetPassword"
                :label="$t('password.' +
                  (resetPassword ? 'notRequired' :'required'))"
                :append-icon="isPasswordHidden ? 'visibility' : 'visibility_off'"
                :type="isPasswordHidden ? 'password' : 'text'"
                :error-messages="validationErrors(
                  'user.password',
                  {
                    required: 'required.password'
                  })"
                @click:append="isPasswordHidden = !isPasswordHidden"
                @blur="$v.user.password.$touch"/>
            </v-col>

            <v-col
              v-if="isCreate"
              cols="12" sm="6">
              <v-switch
                v-model="resetPassword"
                :disabled="isOnboarding && sendOnboardingInfo"
                :label="$t ('password.reset')"/>
            </v-col>

            <v-col
              v-if="user.totpSecretPresent"
              cols="12">
              <v-switch
                v-model="resetTotp"
                :label="$t('resetTotp')"/>
            </v-col>

            <v-col
              v-if="isOnboarding"
              cols="12">
              <v-switch
                v-model="sendOnboardingInfo"
                persistent-hint
                :label="$t ('onboarding.label')"
                :hint="$t ('onboarding.hint')"/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <floating-button
            color="primary"
            :l-offset="2"
            :b-offset="2">
            {{ $t(`general.button.${operationName}`) }}
          </floating-button>
          <v-spacer/>
          <v-btn
            v-t="'general.button.cancel'"
            text
            @click="onCancel"/>
        </v-card-actions>
      </form>
    </v-card>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

  import {required, requiredIf} from 'vuelidate/lib/validators'

  import {userName, email} from '@/app/validators'

  import FloatingButton from '@/app/core/components/FloatingButton'

  import ContentHeightReporter from '@/app/core/mixins/ContentHeightReporter'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import _cloneDeep from 'lodash/cloneDeep'
  import _isEqual from 'lodash/isEqual'

  import UserGroupInput from './UserGroupInput'

  const INITIAL_USER = {
    id: '',
    clientId: '',
    active: true,
    name: '',
    displayName: '',
    emailAddress: '',
    preferredLanguage: '',
    groups: [],
    password: null,
    totpSecretPresent: false,
    admin: false
  }

  const ADMIN_USER_GROUP = 'administrator'

  export default {
    name: 'UserCreateUpdate',

    components: {
      FloatingButton,
      UserGroupInput
    },

    mixins: [ContentHeightReporter, validationMixins],

    props: {
      userData: {
        type: Object,
        required: true
      },
      isCreate: {
        type: Boolean,
        default: false
      },
      isOnboarding: {
        type: Boolean,
        default: false
      },
      clientGroups: {
        type: Array,
        default: function () {
          return []
        }
      }
    },

    data: () => ({
      defaultUser: null,
      isLoading: true,
      parentName: '',
      clients: [],
      userGroups: [],
      user: {},
      changePassword: false,
      isPasswordHidden: true,
      resetTotp: false,
      resetPassword: false,
      sendOnboardingInfo: false
    }),

    validations: {
      user: {
        clientId: {
          required: requiredIf (function () {
            return this.isCreate && this.needSpecifyClient
          })
        },
        name: {required, userName},
        displayName: {required},
        emailAddress: {required, email},
        preferredLanguage: {required},
        groups: {required},
        password: {
          required: requiredIf (function () {
            return this.isPasswordRequired
          })
        }
      }
    },

    computed: {
      ...mapState ('auth', [
        'userLoginData'
      ]),

      ...mapGetters ('auth', [
        'actingClientId',
        'actingClientName',
        'hasClient',
        'clientName',
        'clientId',
        'hasAnyOfPermissions',
        'mayManageAllEntities'
      ]),

      clientOptions () {
        return this.clients.map (({id, name}) =>
          (
            {
              text: `${name} (${id})`,
              value: id
            }
          ))
      },

      needSpecifyClient () {
        return !this.mayCreateAdminUsers ||
          (this.mayCreateAdminUsers && !this.user.admin)
      },

      mayCreateAdminUsers () {
        return !this.hasClient && this.isCreate && !this.isOnboarding
      },

      operationName () {
        return this.isCreate ? 'create' : 'update'
      },

      isPasswordRequired () {
        return (this.isCreate && !this.resetPassword) || this.changePassword
      },

      preferredLanguageOptions () {
        return ['en', 'de'].map (this.langToPreferredLanguageOption)
      }
    },

    watch: {
      user: {
        handler (newValue) {
          if (this.defaultUser && !_isEqual (newValue, this.defaultUser)) {
            this.$emit ('unsavedChanges', true)
          } else {
            this.$emit ('unsavedChanges', false)
          }
        },
        deep: true
      },

      'user.admin' (isAdmin) {
        if (isAdmin) {
          this.user.clientId = undefined

          this.user.groups.push (
            ...this.userGroups.filter (g =>
              g.name === ADMIN_USER_GROUP).map (g => g.id).filter (i =>
              !this.user.groups.includes (i)))
        }
      },

      userData (newData) {
        this.populateUserObject (newData)
      },

      resetPassword (newValue) {
        if (newValue) this.user.password = ''
      },

      sendOnboardingInfo (newValue) {
        if (newValue) this.resetPassword = true
      }
    },

    created () {
      this.populateUserObject (this.userData)

      if (this.isCreate) {
        this.populateClients ()
      }

      this.listUserGroups ()
      this.resetPassword = this.isOnboarding
      this.sendOnboardingInfo = this.isOnboarding
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        getClientNameExt: 'cache/getClientNameExt',
        fetchData: 'request/fetchData'
      }),

      langToPreferredLanguageOption (lang) {
        return {
          value: lang,
          text: this.$t (`preferredLanguages.${lang}`)
        }
      },

      // populate this component's user with the values from the dataObject
      populateUserObject (dataObject) {
        this.user = _cloneDeep (INITIAL_USER)
        Object.assign (this.user, dataObject)

        if (this.user.id) {
          this.loadUser (this.user.id)
        }

        if (this.isCreate) {
          this.user.clientId = dataObject.clientId || this.actingClientId
        }

        this.$v.$reset ()
      },

      loadUser (userId) {
        this.isLoading = true

        this.fetchData ({
          op: 'user/load',
          params: {id: userId},
          cb: data => {
            this.user = data.userData

            if (this.user.clientId) {
              (async () => {
                this.parentName =
                  await this.getClientNameExt (this.user.clientId)
              }) ()
            }
            this.defaultUser = _cloneDeep (this.user)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      // retrieve the list of clients
      async populateClients () {
        if (this.hasAnyOfPermissions ([
          'ManageAllEntities', 'ManageSubEntities', 'ManageSubClients'
        ])) {
          await this.fetchData ({
            op: 'client/list/all',
            cb: data => {
              this.clients = data.list
              this.clients.sort ((a, b) => a.name.localeCompare (b.name))
            }
          })
        }
        // add own client to available clients
        if (this.hasClient) {
          this.clients = [
            {
              id: this.actingClientId,
              name: this.actingClientName
            },
            ...this.clients
          ]
        }
      },

      // retrieve the list of user groups
      listUserGroups () {
        this.fetchData ({
          op: 'user/listGroups',
          cb: data => {
            this.userGroups = data.list

            if (this.isOnboarding) {
              this.user.groups = this.userGroups.filter (
                g => this.clientGroups.includes (g.name)).map (g => g.id)
            }
          }
        })
      },

      // store or update the user
      processUserData () {
        if (!this.$v.$invalid) {
          this.fetchData ({
            op: this.isCreate ? 'user/create' : 'user/update',
            params: {
              id: this.user.id,
              clientId: this.user.clientId,
              active: this.user.active,
              name: this.user.name,
              displayName: this.user.displayName,
              emailAddress: this.user.emailAddress,
              preferredLanguage: this.user.preferredLanguage,
              groups: this.user.groups,
              password: (this.isPasswordRequired && !this.resetPassword)
                ? this.user.password
                : null,
              totpResetNeeded: this.resetTotp
            },
            cb: (data) => {
              this.displaySuccessMessage (
                this.$t (`saved.${this.operationName}`))

              this.$emit ('unsavedChanges', false)
              this.$emit ('success', {
                sendOnboardingInfo: this.sendOnboardingInfo,
                userId: data.id,
                username: this.user.name
              })
              this.reset ()
            }
          })
        } else {
          this.$v.$touch ()
        }
      },

      reset () {
        if (this.isCreate) {
          this.populateUserObject (this.userData)
        }
      },

      onCancel () {
        this.$emit ('unsavedChanges', false)
        this.$emit ('cancel')
      }
    }

  }
</script>
