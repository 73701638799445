<template>
  <router-link
    v-if="link"
    :to="{name: 'user.view', params: {id}}"
    v-text="userName || id"/>
  <span v-else>
    {{ userName || id }}
  </span>
</template>

<script>

  export default {
    name: 'ClientLink',

    props: {
      id: {
        type: Number,
        required: true
      },
      link: {
        type: Boolean,
        default: false
      },
      userName: {
        type: String,
        default: null
      }
    }
  }
</script>
