import {mapActions} from 'vuex'

export default {
  methods: {
    ...mapActions ('layout', ['updateContentHeight'])
  },

  mounted () {
    this.updateContentHeight ()
  },

  updated () {
    this.updateContentHeight ()
  },

  destroyed () {
    this.updateContentHeight (0)
  }
}
