<template>
  <div class="nameValueBlock">
    <div
      v-if="title"
      class="text-subtitle-1 primary--text"
      v-text="title"/>
    <value v-if="items.length == 0" :value="$t('general.pagination.noDataText')"/>
    <value
      v-for="item in itemsReduced"
      :key="item.id"
      :link="item.link"
      :value-slot="item.valueSlot"
      :value="item.value"/>
    <name-value v-if="items.length > 6" :name="!showMore? '...' : ' '" @click.prevent="toggle">
      <template #value>
        <div class="text-right">
          <v-tooltip top>
            <template #activator="{on}">
              <v-btn small icon v-on="on" @click.prevent="toggle">
                <v-icon>
                  {{ showMore ? 'expand_less' : 'expand_more' }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ showMore ? $t('general.label.less') : $t('general.label.all') }}
            </span>
          </v-tooltip>
        </div>
      </template>
    </name-value>
  </div>
</template>

<script>
  import Value from './Value'
  import NameValue from './NameValue'

  export default {
    name: 'ValueBlockShowMore',

    components: {
      Value,
      NameValue
    },

    props: {
      title: {
        type: String,
        default: undefined
      },
      items: {
        type: Array,
        default: undefined
      }
    },

    data () {
      return {
        showMore: false
      }
    },

    computed: {
      itemsReduced () {
        return this.showMore ? this.items : this.items.slice (0, this.items.length === 6 ? 6 : 5)
      }
    },

    methods: {
      toggle () {
        this.showMore = !this.showMore
      }
    }

  }
</script>

<style scoped>
.subtitle-1 {
  text-align: center;
}
</style>
