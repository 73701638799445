<template>
  <v-row
    v-if="name && valueString"
    align="center"
    class="nameValue">
    <v-col class="pa-1">
      <slot
        v-if="keySlotValue"
        name="keySlotValue"/>
      <slot v-else>
        <span
          :title="name"
          v-text="name"/>
      </slot>
    </v-col>
    <v-col
      v-if="!isValueStringEmpty"
      class="pa-1 valueContainer">
      <slot
        v-if="slotValue || $slots.value"
        class="value"
        name="value"/>
      <slot v-else>
        <router-link
          v-if="link"
          class="value"
          :style="valueStyle"
          :to="link">
          {{ valueString }}
        </router-link>
        <span
          v-else
          :class="[...valueClasses, 'value']"
          :title="valueString"
          v-text="valueString"/>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'NameValue',

    props: {
      name: {
        type: [String, Boolean],
        default: undefined
      },
      keySlotValue: {
        type: [Object, Array, String],
        default: undefined
      },
      value: {
        type: [String, Number, Boolean, Array],
        default: undefined
      },
      link: {
        type: Object,
        default: undefined
      },
      slotValue: {
        type: [Object, Array, String],
        default: undefined
      },
      arraySeparator: {
        type: String,
        default: ', '
      },
      valueStyle: {
        type: Object,
        default: () => ({})
      },
      valueClasses: {
        type: Array,
        default: () => ([])
      }
    },

    computed: {
      valueString () {
        let valStr

        switch (typeof this.value) {
          case 'boolean':
            valStr = this.$t (`general.${this.value ? 'yes' : 'no'}`)
            break

          case 'object':
            if (Array.isArray (this.value)) {
              valStr = this.value.join (this.arraySeparator)
            }
            break

          default:
            valStr = `${this.value}`
        }

        return valStr
      },

      isValueStringEmpty () {
        return this.valueString.trim () === ''
      }
    },

    watch: {
      slotValue () {
        this.updateSlot ()
      },
      keySlotValue () {
        this.updateKeySlot ()
      }
    },

    created () {
      this.updateKeySlot ()
      this.updateSlot ()
    },

    methods: {
      updateSlot () {
        if (this.slotValue) {
          this.$slots.value = this.slotValue
        }
      },
      updateKeySlot () {
        if (this.keySlotValue) {
          this.$slots.keySlotValue = this.keySlotValue
        }
      }
    }
  }
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_colors';

$oddColor: map-get($grey, "lighten-2");
$evenColor: map-get($grey, "lighten-5");

.nameValue {
  background-color: $oddColor;
}

.nameValue:nth-child(even) {
  background-color: $evenColor;
}

.valueContainer {
  min-width: 0;

  > .value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
