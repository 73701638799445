<messages>["../UserCreateUpdate"]</messages>
<template>
  <v-card>
    <template v-if="groups.length > 0">
      <v-card-title>
        <div>
          <div class="text-h5">
            {{ names }}
          </div>
          <div
            v-t="'title.permissionsSub'"
            class="cgwng-subheading"/>
        </div>
      </v-card-title>

      <v-card-text>
        <value-block :title="$t('permissions')" :items="permissions"/>
      </v-card-text>
    </template>
    <v-card-title
      v-else v-t="'title.noPermissions'"
      class="text-h5"/>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        v-t="'general.button.close'" text
        @click="$emit('close')"/>
    </v-card-actions>
  </v-card>
</template>

<script>
  import ValueBlock from '@/app/core/components/ValueBlockShowMore'

  export default {
    components: {
      ValueBlock
    },

    props: {
      groups: {
        type: Array,
        required: true
      }
    },

    computed: {
      names () {
        return this.groups.reduce (
          (acc, g) => acc === '' ? g.name : acc + ', ' + g.name,
          '')
      },

      permissions () {
        const perms = []

        this.groups.forEach ((g) => {
          g.permissions.forEach ((p) => {
            if (!perms.find ((i) => i.id === p)) {
              perms.push ({value: this.$t (`permission.${p}`), id: p})
            }
          })
        })

        perms.sort ((a, b) => a.value > b.value)

        return perms
      }
    }
  }
</script>
