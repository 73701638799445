<messages>["./UserCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw0>
    <v-col v-if="createAlert && createData" cols="12">
      <v-alert v-model="createAlert" type="success" dismissible>
        <i18n path="createSuccess">
          <template #name>
            <user-link
              :id="createData.userId"
              :user-name="createData.username"
              link/>
          </template>
        </i18n>
      </v-alert>
    </v-col>
    <v-col cols="12">
      <user-create-update
        :is-create="true"
        :is-onboarding="isOnboarding"
        :user-data="userData"
        :client-groups="clientGroups"
        @success="onSuccess ($event)"
        @cancel="back"/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import UserCreateUpdate from './components/UserCreateUpdate'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import UserLink from '@/app/core/components/UserLink'

  import {mapActions, mapMutations} from 'vuex'

  export default {
    name: 'UserCreate',

    components: {
      BaseLayout,
      UserCreateUpdate,
      UserLink
    },

    props: {
      isOnboarding: {
        type: Boolean,
        default: false
      },
      clientId: {
        type: Number,
        default: undefined
      },
      clientGroups: {
        type: Array,
        default: function () {
          return []
        }
      }
    },

    data () {
      return {
        userData: undefined,
        createAlert: false,
        createData: null
      }
    },

    computed: {
      initialData () {
        return {
          active: true,
          clientId: this.clientId
        }
      }
    },

    created () {
      this.userData = this.initialData
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      /**
       * Send onboarding e-mail in case the user was created as part of an
       * onboarding process.
       *
       * Note that the password reset e-mail has already been sent during user
       * creation.
       *
       * @param {Object} data      meta data regarding the user creation
       */
      onSuccess (data) {
        this.addEvent ({
          type: 'success',
          message: this.$t ('createSuccess', {link: data.username}),
          objects: [{
            name: data.username,
            link: {name: 'user.view', params: {id: data.userId}}
          }]
        })

        if (this.isOnboarding) {
          if (data.sendOnboardingInfo) {
            this.fetchData ({
              op: 'client/mgmt/onboard',
              params: {
                clientId: this.clientId,
                userId: data.userId,
                userAction: 'notify-client'
              },
              cb: data => {
                this.displaySuccessMessage (this.$t ('onboarding.success'))
                this.$router.push ({name: 'client.create'})
              }
            })
          }
        }
        this.createData = data
        this.createAlert = true
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
